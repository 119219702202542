import React, { useState } from "react";
import "../assets/css/Home.css";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from "../components/ContactForm";
import '@fontsource-variable/dancing-script';

export default ({ data }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <Layout transparent>
        <SEO
        title="Home"
        description="Paolos Liqueur's Norwich | Try Authentic Italian Limoncello"
        ></SEO>
        <div className="home-background">
          <div className="container">
            <Container className="p-2">
              <Row>
                <Col sm={12} md={12} lg={12} className="p-2">
                  <div className="sicily-norwich-pizzeria">
                      <h1 className="italian-heading">Authentic Italian Crema Di Limoncello by Paolo's Liqueurs</h1>
                      <p className="pt-2">
                        Paolo's Liqueur's, produce small batch, handmade Creama Di Limoncello using only the most authentic ingredients. We use a traditional family recipe perfected over generations to ensure that our Crema Di Limoncello is some of the best you have ever tasted.
                      </p>
                      <p>All orders will require you to confirm that you are over at least 18 years old, and we will not deliver to any public place.</p>
                    </div>
                    <div className="weddingsign">
                      <Img fluid={data.weddingfavoursign.childImageSharp.fluid} />
                    </div>
                </Col>
                <Col sm={12} md={6} lg={6} className="p-2">
                  <div className="sicily-norwich-pizzeria">
                      <h2 className="pt-4 italian-heading">Feedback and contact form</h2>
                      <p className="pt-2">
                        If you would like the opportunity to try our delicious Crema Di Limoncello please drop us a message and let us know! We can help arrange a sample bottle sent directly to you and will then be in contact about next steps in placing an order.
                      </p>
                      <ContactForm/>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={6} className="p-2">
                  <div className="sicily-norwich-pizzeria">
                  <h2 className="pt-2 italian-heading">Bespoke label and packaging for a recent customer</h2>
                    <Img className="weddingsign"fluid={data.weddingfavour.childImageSharp.fluid} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  );
};
export const query = graphql`
  query  {
    sicilypasta: file(relativePath: { eq: "label.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
      weddingfavour: file(relativePath: { eq: "weddingfavours.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
      weddingfavoursign: file(relativePath: { eq: "weddingfavoursign.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
